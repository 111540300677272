import { Component, Injector } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { AlertController, LoadingController, NavController } from "@ionic/angular";
import { Storage } from "@ionic/storage";
import { UserMapper } from "src/data/mapper/user.mapper";
import { UserModel } from "src/model/user.model";
import config from "src/config";
import { EventService } from "./event.service";
import { Location } from "@angular/common";
import { NavParamsService } from "./nav-params.service";
import { UnprocessableEntity } from "src/entity/error/unprocessable.entity";

@Component({
    template: ''
})
export abstract class BaseApplicationPage {
    // constant variable
    private readonly DEFAULT_ERROR_MESSAGE: string = 'Sorry, something went wrong. Please try again later.';
    private readonly DEFAULT_LOADER_MESSAGE: string = 'Loading...';
    
    // Depedency Injection
    protected alertController: AlertController;
    protected loadingController: LoadingController;
    protected storage: Storage;
    protected router: Router;
    protected route: ActivatedRoute;
    protected navController: NavController;
    protected eventService: EventService;
    protected navParamsService: NavParamsService;

    // common variable
    public user: UserModel;
    public routeParams: any;
    protected readonly config: any = config;

    constructor(injector: Injector) {
        this.navParamsService = injector.get(NavParamsService);
        this.alertController = injector.get(AlertController);
        this.loadingController = injector.get(LoadingController);
        this.storage = injector.get(Storage);
        this.router = injector.get(Router);
        this.route = injector.get(ActivatedRoute);
        this.navController = injector.get(NavController);
        this.eventService = injector.get(EventService);
        this.setAttributes();
    }

    private async setAttributes(): Promise<void>
    {
        const params = this.navParamsService.getNavParams();
        if(params) this.routeParams = params;
        this.user = (new UserMapper().mapFrom([
            JSON.parse(await this.storage.get(this.config.USER_DATA_IDENTIFIER))?.user
        ]))[0];
    }

    protected async displayError(message: string = this.DEFAULT_ERROR_MESSAGE): Promise<void> 
    {
        const alert = await this.alertController.create({
            header: 'Error',
            message: message,
            buttons: ['Ok']
        });
        alert.present();
    }

    protected async showLoading(message: string = this.DEFAULT_LOADER_MESSAGE): Promise<HTMLIonLoadingElement> 
    {
        const loading = await this.loadingController.create({
            message: message,
            spinner: 'circles',
        });

        loading.present();
        return loading;
    }

    public navigateTo<T = any>(url: string, params?: T): void 
    {
        this.navParamsService.setNavParams(params);
        this.navController.navigateForward(url);
    }

    public navigateBack(url?: string): void
    {
        if(url) {
            this.navController.navigateBack(url);
        } else {
            this.navController.pop();
        }
    }

    protected mapError(data: UnprocessableEntity) {
        const errors: {
          name: string,
          messages: string[]
          first: () => string
        }[] = [];
        for (const key in data.errors) {
          if (Object.prototype.hasOwnProperty.call(data.errors, key)) {
            const messages = data.errors[key];
            errors.push({
              name: key,
              messages: messages,
              first: (): string => {
                if (Object.prototype.hasOwnProperty.call(messages, 0)) {
                  return messages[0];
                }
                return null;
              }
            })
          }
        }
        return errors;
    }
}