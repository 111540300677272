import { Storage } from '@ionic/storage';
import axios, { AxiosResponse } from "axios";
import config from 'src/config';
import { Injectable } from '@angular/core';
import { SearchPersonParam } from 'src/core/chat/interfaces/search-person-param.interface';
import { SearchPersonEntity } from 'src/entity/chat/search-person.entity';
import { ChatRoomEntity } from 'src/entity/chat/chat-room.entity';


@Injectable()
export class VideoCallRepository  {
    private http = axios.create({
        baseURL: config.API_URL
    });

    constructor(
        private storage: Storage
    ) {
    }

    private async getToken(): Promise<String> {
        return await this.storage.get(config.ACCESS_TOKEN_IDENTIFIER)
    }


    public async searchPerson(params: SearchPersonParam): Promise<AxiosResponse<SearchPersonEntity>> {
        const token = await this.getToken();
        return await this.http.post('/video-call/search-employees', params, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }


    public async searchGroup(data: { name: String}): Promise<AxiosResponse<ChatRoomEntity>> {
        const token = await this.getToken();
        return await this.http.post('/video-call/search-groups', data , {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async startIndividualCall(params: { user_id: number }): Promise<AxiosResponse<any>> {
        const token = await this.getToken();
        return await this.http.post('/live-chat/start-individual-chat', params, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }
}