import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private filterSubject = new Subject<any>();
  filter$ = this.filterSubject.asObservable();

  sendFilter(filter: any) {
    this.filterSubject.next(filter);
  }
}