import { Component, NgZone, ViewChild } from '@angular/core';

import { Platform, AlertController, LoadingController, NavController, ToastController, IonRouterOutlet } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { Storage } from '@ionic/storage';

import { EventService } from './event.service';
import { TranslateService } from '@ngx-translate/core';
import { NavParamsService } from 'src/app/nav-params.service';

import axios from 'axios';
import config from '../config';
import { codePush } from 'capacitor-codepush';
import { SunfishSsoClientService } from './services/sunfish-sso-client/sunfish-sso-client.service';
import { ActivatedRoute, Router } from '@angular/router';

import Echo from 'laravel-echo';
import { GlobalEvent, GlobalEventTypeEnum } from 'src/core/post/interfaces/global-event-interface';
import { EntityPermissionService } from './entity-permission.service';
import { App } from '@capacitor/app';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent {
  @ViewChild(IonRouterOutlet, { static : true }) routerOutlet: IonRouterOutlet;
  /**
   * Token name
   */
  TOKEN: string = config.ACCESS_TOKEN_IDENTIFIER;

  /**
   * Api Url
   */
  API_URL: string = config.API_URL;

  private echo: Echo<any>;

  protected globalLongPollTimeout: NodeJS.Timeout;
  protected globalLongPollPromize: Promise<any> = null;
  protected globalLongPollEventIds: Array<number> = [];

  constructor(
    private platform: Platform,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private navCtrl: NavController,
    public events: EventService,
    private storage: Storage,
    private translate: TranslateService,
    private oneSignal: OneSignal,
    public navParams: NavParamsService,
    private zone: NgZone,
    private sunfishSsoClient: SunfishSsoClientService,
    private route: ActivatedRoute,
    private router: Router,
    private entityPermissionService: EntityPermissionService
  ) {
    this.initializeApp();

    /**
     * hardware back button - for handle App back button in phone 
     */
    this.platform.backButton.subscribeWithPriority(10, () => {
      if (this.routerOutlet.canGoBack()) {
        this.navCtrl.pop();
        return
      }
      if (this.router.url === '/chats/chat') {
        this.navCtrl.pop();
        return;
      }
      App.exitApp()
    });
  }

  async initializeApp() {
    await this.storage.create();
    this.platform.ready().then(async () => {
      StatusBar.setBackgroundColor({
        color: '#4819e3'
      });
      StatusBar.setStyle({
        style: Style.Dark
      })

      if (this.platform.is('cordova') || this.platform.is('capacitor')) {
        SplashScreen.hide();
      }

      // Initialize global event websocket
      this.initGlobalWebsocket();
      // Initialize global event long poll
      this.initGlobalLongPolling();

      // Start one signal
      this.oneSignal.startInit(config.ONE_SIGNAL_KEY, config.SENDER_ID);
      this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);
      this.oneSignal.handleNotificationReceived().subscribe(notif => {
        // do something when notification is received
        console.log(notif);
      });
      this.oneSignal.handleNotificationOpened().subscribe(async (data) => {
        // do something when notification is opened
        const token = await this.storage.get(config.ACCESS_TOKEN_IDENTIFIER);
        if(token == null) {
          return false;
        }

        console.log(data);
        
        setTimeout(() => {
          this.zone.run(() => {
            if (data.notification.payload.additionalData.target_page !== null && data.notification.payload.additionalData.target_page !== undefined) {
              if (data.notification.payload.additionalData.nav_params !== null && data.notification.payload.additionalData.nav_params !== undefined) {
                this.navParams.setNavParams(data.notification.payload.additionalData.nav_params);
              }
              console.log(data.notification.payload.additionalData.target_page);
              this.navCtrl.navigateForward(data.notification.payload.additionalData.target_page);
            }
          });
        }, 300);
      });

      this.oneSignal.endInit();

      // Codepush in background

      // const downloadProgress = (progress) => { console.log(`Downloaded ${progress.receivedBytes} of ${progress.totalBytes}`); };
      // this.codePush.sync({}, downloadProgress).subscribe(syncStatus => {
      //   console.log(syncStatus);
      // });

      // Codepush in background if in cordova/capacitor
      if (this.platform.is('cordova') || this.platform.is('capacitor')) {
        await codePush.sync({
          onSyncStatusChanged: (data) => {
            console.log('Code push status', data);
          },
          onSyncError: (error) => {
            console.log(error);
            console.log('Failed to sync');
          }
        }, (downloadProgress) => {
          console.log("Downloading " + downloadProgress.receivedBytes + " of " + downloadProgress.totalBytes);
        });
      }
    });

    this.storage.get('language').then(language => {
      if (language === undefined || language === null || language.length <= 0) {
        language = 'en';
      }
      this.translate.setDefaultLang(language);
    });

    this.events.subscribe('app:changed_language', data => {
      const language = data.language;
      this.storage.set('language', language).then(() => {
        this.translate.setDefaultLang(language);
      });
    });

    this.events.subscribe('user:login', () => {
      this.handleLogin();
    });

    this.events.subscribe('user:logout', () => {
      this.doLogout();
    });

    this.storage.get(this.TOKEN).then((accessToken) => {
      // tslint:disable-next-line: triple-equals
      if (accessToken != null && accessToken != undefined && accessToken.length > 0) {
        this.events.publish('user:login');
      } else {
        this.events.publish('user:logout');
      }
    });

    this.route.queryParams
      .subscribe(params => {
        const redirectToPage = params.redirect_to;
        this.storage.set('redirect_to', redirectToPage);
      }
    );

    this.events.subscribe('SUNFISH_SSO:OPEN_LINK', async () => {
      const loader = await this.loadingCtrl.create({
        message: 'Loading...'
      });
      loader.present();

      const result = await this.sunfishSsoClient.requestToken()
      if (result.error) {
        loader.dismiss();
        this.alertCtrl.create({
          header: "Error",
          message: result.errorMessage,
          buttons: ['Ok']
        }).then(alert => {
          alert.present();
        });
        return
      }

      loader.dismiss();
      this.sunfishSsoClient.openSSOPage(result?.data?.url);
    });

    // Inject All API to add app version on header for each http request
    axios.defaults.headers.common['app-platforms'] = this.platform.platforms();
    axios.defaults.headers.common['pwa-version'] = config?.PWA_VERSION;
    axios.defaults.headers.common['android-version'] = config?.ANDROID_VERSION;
    axios.defaults.headers.common['ios-version'] = config?.IOS_VERSION;
  }

  /**
   * Redirects the user to the logged in home page when
   * the "user:login" event is transmitted
   */
  async handleLogin() {
    // const loader = await this.loadingCtrl.create({
    //   message: 'Loading...'
    // });

    // await loader.present();
    const accessToken = await this.storage.get(this.TOKEN);
    /**
     * check the access token. Logged out the user if the access token is empty
     */
    if (accessToken == null || accessToken === undefined || accessToken.length <= 0) {
      // loader.dismiss();
      this.events.publish('user:logout');
      return false;
    }

    axios.get(`${this.API_URL}/auth/user-data`, {
      headers: {
        Authorization: 'Bearer ' + accessToken
      }
    }).then(async (response) => {
      const data = response.data;
      if (data.error) {
        if (data.error_code === 401 || data.error_code === '401') {
          this.events.publish('user:logout');
        } else {
          const alert = await this.alertCtrl.create({
            header: 'Error',
            message : data.message,
            buttons: ['Ok']
          });
          alert.present();
        }
        return false;
      }
      await this.storage.set(config.USER_DATA_IDENTIFIER, JSON.stringify(data));
      this.initGlobalLongPolling();

      // loader.dismiss();
      this.storage.get('redirect_to')
        .then(async redirectTo => {
          if (redirectTo != undefined && redirectTo != null && redirectTo.length > 0) {
            this.storage.remove('redirect_to');

            await this.router.navigate(
              [], 
              {
                relativeTo: this.route,	
                queryParams: {'redirect_to': ''}, 
                queryParamsHandling: 'merge', // remove to replace all query params by provided
              });

            this.navCtrl.navigateForward(redirectTo);
          } else {
            this.navCtrl.navigateRoot('/home');
          }
        });


      // Upload Onesignal token
      setTimeout(() => {
        this.oneSignal.getIds().then(onesignalData => {
          axios.post(`${config.API_URL}/onesignal/save-device`, {
            user_id: onesignalData.userId,
            push_token: onesignalData.pushToken
          }, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }).then(onesignalResponse => {
            // loader.dismiss();
            // this.navCtrl.navigateRoot('/home');
          }).catch(error => {
            console.log(error);
            // loader.dismiss();
            // this.navCtrl.navigateRoot('/home');
          });
        }).catch(error => {
          // loader.dismiss();
          // this.navCtrl.navigateRoot('/home');
        });
      }, 200);
    }).catch(async (error) => {
      // loader.dismiss();
      if (error.status === 401) {
        this.events.publish('user:logout');
        return false;
      }
      const alert = await this.alertCtrl.create({
        header: 'Error',
        message: 'Sorry, something went wrong. Please log in again.',
        buttons: ['Ok']
      });
      alert.present();
    });
  }

  async initGlobalWebsocket()
  {
    this.echo = new Echo({
      broadcaster: 'socket.io',
      host: config.WEBSOCKET_URL,
      transports: [
        'websocket', 'polling', 'flashsocket'
      ]
    });

    this.echo.connector.socket.on('connect', () => { 
      console.log('CONNECTED TO WEBSOCKET');
    });

    this.echo.connector.socket.on('disconnect', () => {
      console.log('DISCONNECTED FROM WEBSOCKET');
    });

    this.echo.channel(`${config.WEBSOCKET_PREFIX}_global_event`).listen(
      `.${config.WEBSOCKET_GLOBAL_EVENT_ID}`, async(data:any) => {
          console.log('ACCEPTING GLOBAL EVENT');
          console.log(data);
          this.handleGlobalEvent(data)
      }
    )
  }

  async handleGlobalEvent(globalEventData : GlobalEvent) : Promise<void>
  {
    const user = await this.storage.get(config.USER_DATA_IDENTIFIER);
    let parsedUser = JSON.parse(user);
    let rejectedIdsArray = [];
    if (typeof globalEventData !== 'undefined' && globalEventData !== null && 
      typeof globalEventData.data !== 'undefined' && globalEventData.data !== null &&
      typeof globalEventData.data.rejected_ids !== 'undefined') {
      rejectedIdsArray = Object.values(globalEventData?.data?.rejected_ids);
    }

    switch(globalEventData.event_type) {
      case GlobalEventTypeEnum.incoming_call:
        console.log('INCOMING CALL');
        if(parsedUser && parsedUser.id == globalEventData.data.recipient_id) {
          this.navParams.setNavParams({
            calling:false
          });
          this.navCtrl.navigateForward('chat/chat-detail/video-call/' + globalEventData.data.chat_room_call_id);
        }
        break;

      case GlobalEventTypeEnum.end_call_from_caller:
        console.log('END CALL FROM CALLER');
        if(rejectedIdsArray.includes(parsedUser.id)) {
          this.events.publish(GlobalEventTypeEnum.end_call_from_caller);    
        }
        break;

      case GlobalEventTypeEnum.end_call_from_receiver:
          console.log('END CALL FROM RECEIVER');
          if(rejectedIdsArray.includes(parsedUser.id)) {
            this.events.publish(GlobalEventTypeEnum.end_call_from_receiver, globalEventData.data.meet_room_uuid);    
          }
          break;
        
      case GlobalEventTypeEnum.end_call_from_caller_group:
          console.log('END CALL FROM GROUP CALLER');
          if(rejectedIdsArray.includes(parsedUser.id)) {
            this.events.publish(GlobalEventTypeEnum.end_call_from_caller_group, globalEventData.data);    
          }
          break;

      case GlobalEventTypeEnum.no_answer:
            console.log('NO ANSWER');
            if(rejectedIdsArray.includes(parsedUser.id)) {
              this.events.publish(GlobalEventTypeEnum.no_answer , globalEventData.data);    
            }
            break;

      case GlobalEventTypeEnum.testing:
        break
      default:
        // Do nothing
        break;
    }
  }

  protected async checkNewEvents(): Promise<void>
  {
    // prevent spam api when previous request is not completed
    if(this.globalLongPollPromize) return;

    try {
      const token = await this.storage.get(config.ACCESS_TOKEN_IDENTIFIER);
      this.globalLongPollPromize = axios.get(`${config.API_URL}/global-long-polling/new-events`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const { data } = await this.globalLongPollPromize;
      if(data.has_new_event) {
        data.events
        .filter(event => !this.globalLongPollEventIds.includes(event.id)) // for prevent multiple trigger
        .forEach(event => {
          if((Object.values(GlobalEventTypeEnum) as Array<string>).includes(event.name)) {
            this.handleGlobalEvent(event.data);
          } else {
            this.events.publish('global-long-poll:' + event.name, event);
          }
          this.globalLongPollEventIds.push(event.id);
        });
      }
    } catch (error) {
      console.error(error);
      clearTimeout(this.globalLongPollTimeout);
      this.globalLongPollTimeout = null;
    } finally {
      this.globalLongPollPromize = null;
    }
  }

  protected async initGlobalLongPolling(): Promise<void>
  {
    if(this.globalLongPollTimeout) return;

    this.globalLongPollTimeout = setInterval(async () => {
      await this.checkNewEvents();
    }, 12500);
  }

  /**
   * Logs the user out and resets them to the login page
   */
  async doLogout() {
    this.entityPermissionService.resetApiPromise();
    await this.storage.remove(this.TOKEN);
    this.navCtrl.navigateRoot('/login');
    if(this.globalLongPollTimeout) {
      clearTimeout(this.globalLongPollTimeout);
      this.globalLongPollTimeout = null;
    }
  }
}
