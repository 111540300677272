/**
 * Create debounce function for execute code block & prevent from spam execution.
 * @param fn A Callback function will called after timer end.
 * @param delay is a time to execute the callback function.
 */
export const debounce = (fn: Function, delay: number = 300) => {
    let timer: ReturnType<typeof setTimeout>;
    return function (this: any, ...args: any[]) {
        clearTimeout(timer);
        timer = setTimeout(() => fn.apply(this, args), delay);
    };
};

export const beforeDebounce = (fn: Function, debounce: Debounce) => {
    return function (this: any, ...args: any[]) {
        fn.apply(this, args);
        debounce.apply(this, args);
    }
}

export type Debounce<T = any, T2 = any> = (this: T, ...args: T2[]) => void;

export type BeforeDebounce<T = any, T2 = any> = (this: T, ...args: T2[]) => void;