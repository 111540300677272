import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage-angular';

import { Geolocation } from '@ionic-native/geolocation/ngx';
import { QRCodeModule } from 'angularx-qrcode';
import { SQLite } from '@ionic-native/sqlite/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';

import { CommentLikesPageModule } from './ess/forums/comment-likes/comment-likes.module';
import { FeedCommentLikesPageModule } from './ess/feed/feed-comment-likes/feed-comment-likes.module';
import { CreateChatPageModule } from './live-chat/create-chat/create-chat.module';
import { UploadFileModalPageModule } from './live-chat/chat-room/upload-file-modal/upload-file-modal.module';
import { CreateGroupChatPageModule } from './live-chat/create-chat/create-group-chat/create-group-chat.module';

import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';

import { MorePageModule } from './home/more/more.module';
import { PopUpPageModule } from './home/pop-up/pop-up.module';

import { ComponentsModule } from './components/components.module';
import { NgSelect2Module } from 'ng-select2';

import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { ChatRepository } from 'src/data/repository/chat.repository';
import { VideoCallRepository } from 'src/data/repository/video-call.repository';
import { EntityPermissionService } from './entity-permission.service';

// i18n
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    IonicStorageModule.forRoot(),
    QRCodeModule,
    MorePageModule,
    PopUpPageModule,

    CommentLikesPageModule,
    FeedCommentLikesPageModule,
    CreateChatPageModule,
    CreateGroupChatPageModule,
    UploadFileModalPageModule
  ],
  providers: [
    StatusBar,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Geolocation,
    SQLite,
    OneSignal,
    BarcodeScanner,
    NgSelect2Module,
    CallNumber,
    ChatRepository,
    VideoCallRepository,
    CallNumber,
    EntityPermissionService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
