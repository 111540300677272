<ion-tabs>
  <ion-tab-bar
    slot="bottom"
    class="tw-h-16 tw-border-t tw-border-black tw-border-opacity-10 tw-px-3"
  >
    <ion-tab-button class="ion-tab-purple tw-space-y-2" tab="home">
      <svg
        width="23"
        height="21"
        viewBox="0 0 23 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.2 21V13.5882H13.8V21H19.55V11.1176H23L11.5 0L0 11.1176H3.45V21H9.2Z"
          fill="url(#selected-gradient)"
        />
      </svg>
      <ion-label color="dark">Home</ion-label>
    </ion-tab-button>
    <ion-tab-button class="ion-tab-purple tw-relative tw-space-y-2" tab="chats">
      <div class="tw-relative tw-w-fit">
        <svg
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          >
          <path
          d="M19.95 4H17.85V13H4.2V15C4.2 15.55 4.6725 16 5.25 16H16.8L21 20V5C21 4.45 20.5275 4 19.95 4ZM15.75 10V1C15.75 0.45 15.2775 0 14.7 0H1.05C0.4725 0 0 0.45 0 1V15L4.2 11H14.7C15.2775 11 15.75 10.55 15.75 10Z"
          fill="url(#selected-gradient)"
          />
        </svg>
        <ion-badge
          color="danger"
          class="tw-absolute -tw-top-2 tw-h-4 tw-border tw-border-white"
          [ngClass]="{
            '-tw-right-2': notificationCounter.toString().length === 1,
            '-tw-right-3': notificationCounter.toString().length === 2,
            '-tw-right-4': notificationCounter.toString().length === 3
          }"
          *ngIf="notificationCounter > 0"
        >
          {{ notificationCounter > 99 ? '99+' : notificationCounter }}
        </ion-badge>
      </div>
      <ion-label color="dark">Chat</ion-label>
    </ion-tab-button>
    <ion-tab-button class="ion-tab-purple tw-space-y-2" tab="call">
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M19.95 21q-3.125 0-6.175-1.362t-5.55-3.863t-3.862-5.55T3 4.05q0-.45.3-.75t.75-.3H8.1q.35 0 .625.238t.325.562l.65 3.5q.05.4-.025.675T9.4 8.45L6.975 10.9q.5.925 1.187 1.787t1.513 1.663q.775.775 1.625 1.438T13.1 17l2.35-2.35q.225-.225.588-.337t.712-.063l3.45.7q.35.1.575.363T21 15.9v4.05q0 .45-.3.75t-.75.3"
        fill="url(#selected-gradient)"/>
      </svg>
      <ion-label color="dark">Call</ion-label>
    </ion-tab-button>
    <!-- <ion-tab-button class="ion-tab-purple tw-space-y-2" tab="social">
      <svg
        width="31"
        height="17"
        viewBox="0 0 31 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5021 9.5625C17.6073 9.5625 19.467 10.115 20.9781 10.8375C22.3729 11.5175 23.2511 13.0475 23.2511 14.705V17H7.75314V14.7192C7.75314 13.0475 8.63136 11.5175 10.0262 10.8517C11.5372 10.115 13.397 9.5625 15.5021 9.5625ZM5.17014 9.91667C6.59079 9.91667 7.75314 8.64167 7.75314 7.08333C7.75314 5.525 6.59079 4.25 5.17014 4.25C3.74949 4.25 2.58715 5.525 2.58715 7.08333C2.58715 8.64167 3.74949 9.91667 5.17014 9.91667ZM6.62954 11.475C6.15168 11.39 5.67383 11.3333 5.17014 11.3333C3.89156 11.3333 2.67755 11.6308 1.57978 12.155C0.624069 12.6083 0.00415039 13.6283 0.00415039 14.7758V17H5.81589V14.7192C5.81589 13.5433 6.11294 12.4383 6.62954 11.475ZM25.8341 9.91667C27.2548 9.91667 28.4171 8.64167 28.4171 7.08333C28.4171 5.525 27.2548 4.25 25.8341 4.25C24.4135 4.25 23.2511 5.525 23.2511 7.08333C23.2511 8.64167 24.4135 9.91667 25.8341 9.91667ZM31.0001 14.7758C31.0001 13.6283 30.3802 12.6083 29.4245 12.155C28.3267 11.6308 27.1127 11.3333 25.8341 11.3333C25.3304 11.3333 24.8526 11.39 24.3747 11.475C24.8913 12.4383 25.1884 13.5433 25.1884 14.7192V17H31.0001V14.7758ZM15.5021 0C17.646 0 19.3766 1.89833 19.3766 4.25C19.3766 6.60167 17.646 8.5 15.5021 8.5C13.3582 8.5 11.6276 6.60167 11.6276 4.25C11.6276 1.89833 13.3582 0 15.5021 0Z"
          fill="url(#selected-gradient)"
        />
      </svg>
      <ion-label color="dark">Social</ion-label>
    </ion-tab-button> -->
  </ion-tab-bar>
</ion-tabs>

<svg>
  <defs>
    <linearGradient
      id="selected-gradient"
      x1="0%"
      y1="0%"
      x2="0%"
      y2="100%"
    >
      <stop offset="0%" stop-color="#004AAD" />
      <stop offset="100%" stop-color="#CB6CE6" />
    </linearGradient>
  </defs>
</svg>
