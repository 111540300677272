import { Storage } from "@ionic/storage";
import { ChatTypeEnum } from "src/core/chat/enums/chat-type.enum";
import { Mapper } from "src/core/mapper/interfaces/mapper";
import { ChatRoomEntity } from "src/entity/chat/chat-room.entity";
import { ChatRoomModel } from "src/model/chat-room.model";
import config from "src/config/index.js";
import { UserModel } from "src/model/user.model";
import { UserMapper } from "./user.mapper";

export class ChatRoomMapper implements Mapper<{ chatRoom: ChatRoomEntity[], user: UserModel }, ChatRoomModel[]> {

    private async getUser(): Promise<UserModel> {
        const storage = await (new Storage()).create();
        return (new UserMapper().mapFrom([
            JSON.parse(await storage.get(config.USER_DATA_IDENTIFIER))?.user
        ]))[0];
    }

    mapFrom(data: { chatRoom: ChatRoomEntity[], user: UserModel }): ChatRoomModel[] {
        const chatRoomModel: ChatRoomModel[] = [];
        data.chatRoom.forEach(chatRoom => {
            chatRoomModel.push({
                id: chatRoom.id,
                uuid: chatRoom.uuid,
                avatar: chatRoom.to && data.user.id === chatRoom.to ? chatRoom.from_user_avatar : chatRoom.to_user_avatar,
                name: (chatRoom.type === ChatTypeEnum.individual) ? chatRoom.from_user_name : chatRoom.name,
                type: chatRoom.type,
                individualName: chatRoom.individual_name || chatRoom.name || chatRoom.to_user_name || null,
                unreadMessages: chatRoom?.meta?.unread_messages,
                meta: chatRoom.meta,
                isMuted: chatRoom.is_muted,
                isHRChat: chatRoom.is_chat_with_hr === 1,
                isUrgentChat: chatRoom.is_urgent_chat === 1,
                lastMessage: chatRoom?.last_message?.message_content,
                lastTime: chatRoom?.last_message?.created_at,
                createdAt: chatRoom.created_at,
                updatedAt: chatRoom.updated_at,
                deletedAt: chatRoom.deleted_at,
                originalData: chatRoom,
                pinnedAt: chatRoom.pinned_at,
            })
        });

        return chatRoomModel;
    }

    mapTo(data: ChatRoomModel[]): { chatRoom: ChatRoomEntity[], user: UserModel } {
        return null;
    }
}