import { Mapper } from "src/core/mapper/interfaces/mapper";
import { ChatEntity } from "src/entity/chat/chat.entity";
import { ChatModel } from "src/model/chat.model";
import { ChatRoomMapper } from "./chat-room.mapper";
import { UserModel } from "src/model/user.model";

export class ChatMapper implements Mapper<{ chat:ChatEntity, user: UserModel }, ChatModel> {
    mapFrom(data: { chat:ChatEntity, user: UserModel }): ChatModel {
        const chat = data.chat;
        return {
            chatHome: {
                id: chat.home.id,
                userID: chat.home.user_id,
                websocketID: chat.home.websocket_id
            },
            chatRooms: (new ChatRoomMapper()).mapFrom({ chatRoom: chat.chat_rooms.data, user: data.user }),
            groupChatUuids: chat.groupChatUuids,
            isChatAdmin: chat.is_chat_admin,
        }
    }

    mapTo(data: ChatModel): { chat:ChatEntity, user: UserModel } {
        return null;
    }
}