import { AxiosResponse } from "axios";
import { ChatRoomInfoEntity } from "src/entity/chat/chat-room-info.entity";
import { ChatEntity } from "src/entity/chat/chat.entity";
import { SearchPersonEntity } from "src/entity/chat/search-person.entity";
import { SendMessageEntity } from "src/entity/chat/send-message.entity";
import { UploadFileMessageEntity } from "src/entity/chat/upload-file-message.entity";
import { ViewChatEntity } from "src/entity/chat/view-chat.entity";
import { ChatHomeFilterParam } from "../types/chat-home-filter-param.type";
import { MuteChatType } from "../types/mute-chat.type";
import { SendMessageParam } from "./send-message-param.interface";
import { ViewChatParam } from "./view-chat-param.interface";
import { SearchChatEntity } from "src/entity/chat/search-chat.entity";
import { SendSticker } from "src/entity/chat/send-sticker.entity";
import { SpecificChatEntity } from "src/entity/chat/specific-chat.entity";
import { ChatRoomEntity } from "src/entity/chat/chat-room.entity";
import { ChatHREntity } from "src/entity/chat/chat-hr.entity";
import { ChatUrgentEntity } from "src/entity/chat/chat-urgent.entity";
import { UpdateMessageEntity } from "src/entity/chat/update-message.entity";

export abstract class IChatRepository {
    abstract getHomeId(params?: { filter_by: ChatHomeFilterParam[] }): Promise<AxiosResponse<ChatEntity>>

    abstract searchChat(params: { keywords: String }): Promise<AxiosResponse<SearchChatEntity>>

    abstract viewChat(params: ViewChatParam): Promise<AxiosResponse<ViewChatEntity>>

    abstract storeMessage(params: SendMessageParam): Promise<AxiosResponse<SendMessageEntity>>

    abstract pinMessage(params: { uuid: String }): Promise<AxiosResponse>

    abstract unpinMessage(params: { uuid: String }): Promise<AxiosResponse>

    abstract uploadFile(params: FormData): Promise<AxiosResponse<UploadFileMessageEntity>>

    abstract deleteMessage(params: { uuid: String }): Promise<AxiosResponse>

    abstract startIndividualChat(params: { user_id: number }): Promise<AxiosResponse>

    abstract startGroupChat(params: { name: String, participants: number[] }): Promise<AxiosResponse>

    abstract searchPerson(params: { employee_code: String, employee_name: String, employee_job_title: String }): Promise<AxiosResponse<SearchPersonEntity>>

    abstract muteChat(params: { uuid: String, type: MuteChatType }): Promise<AxiosResponse>

    abstract getChatRoomInfo(params: { uuid: String }): Promise<AxiosResponse<ChatRoomInfoEntity>>

    abstract deleteChat(params: { uuid: String }): Promise<AxiosResponse>

    abstract getChatRoomFile(params: { page: number, uuid: String }): Promise<AxiosResponse>

    abstract getChatRoomLink(params: { page: number, uuid: String }): Promise<AxiosResponse>
    
    abstract sendZeroMeta(params: { chat_room_uuid: String }): Promise<AxiosResponse>

    abstract getSpecificChat(params: { message_uuid: String, chat_room_uuid: String, page: number }): Promise<AxiosResponse<SpecificChatEntity>>

    abstract createHRGroupChat(params: { name: String }): Promise<AxiosResponse<ChatHREntity>>

    abstract createUrgentGroupChat(params: { name: String }): Promise<AxiosResponse<ChatUrgentEntity>>

    abstract viewChatV2(params: ViewChatParam): Promise<AxiosResponse<ViewChatEntity>>

    abstract storeMessageV2(params: SendMessageParam): Promise<AxiosResponse<SendMessageEntity>>

    abstract uploadFileV2(params: FormData): Promise<AxiosResponse<UploadFileMessageEntity>>
    
    abstract sendSticker(params: FormData): Promise<AxiosResponse<SendSticker>>
    
    abstract readMessage(params: { chat_room_uuid: String }): Promise<AxiosResponse<{ error: boolean, message: String }>>

    abstract updateMessage(params : { uuid :String , message : string }) : Promise<AxiosResponse<UpdateMessageEntity>>
}