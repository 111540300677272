import config from 'src/config';
import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import Echo from 'laravel-echo';
import { BaseApplicationPage } from 'src/app/app-page.base';
import { FilterService } from 'src/app/filter.service';
import { ChatRepository } from 'src/data/repository/chat.repository';
import { ChatHomeModel } from 'src/model/chat-home.model';
import { ChatRoomModel } from 'src/model/chat-room.model';
import axios from 'axios';
import { ChatMapper } from 'src/data/mapper/chat.mapper';
import { ChatHomeWebsocketEntity } from 'src/entity/websocket/chat-home-websocket.entity';
import { IonTabs, Platform } from '@ionic/angular';
import { App } from '@capacitor/app';
import { debounce } from 'src/utility/debounce';

@Component({
  selector: 'app-bottom-tabs',
  templateUrl: './bottom-tabs.component.html',
  styleUrls: ['./bottom-tabs.component.scss'],
})
export class BottomTabsComponent extends BaseApplicationPage implements OnInit, OnDestroy {
  private echo: Echo<any>;
  private chatRooms: ChatRoomModel[];
  private chatHome: ChatHomeModel;
  public unreadNotificationCounts: number = 0;
  public pendingApprovalCounts: number = 0;
  public notificationCounter: number = 0;
  public unreadMessage: number = 0;

  @ViewChild(IonTabs, { static: true }) private ionTabs: IonTabs;

  constructor(
    private chatRepository: ChatRepository,
    private filterService: FilterService,
    private platform: Platform,
    injector: Injector,
    
  ) {
    super(injector);

    this.eventService.subscribe('chat:leave_room', () => {
      // removing current opened chatroom from local storage
      this.storage.remove('current_chat_room_opened');
    });

    this.eventService.subscribe('chat:open', (chatRoomInfo:any) => {
      // everytime chat room opened 

      // tell the app which chatroom opened
      this.storage.set('current_chat_room_opened', chatRoomInfo.uuid);
      // // reduce unread notification count if the openend chat room has unread message
      if(chatRoomInfo.unread_message != undefined || chatRoomInfo.unread_message != null) {
        this.unreadMessage = this.unreadMessage - chatRoomInfo.unread_message;
        this.sumNotificationCounter();
      }
    })

  }

  private sumNotificationCounter() {
    this.notificationCounter = this.unreadMessage;
  }

  private getUnreadNotificationsCount() {
    this.storage.get(config.ACCESS_TOKEN_IDENTIFIER).then(accessToken => {
      axios.get(`${config.API_URL}/notification/unread/count`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json;'
        }
      }).then(json => {
        const data = json.data;
        this.unreadNotificationCounts = data.notification_unread_counts;
        this.sumNotificationCounter();
      }).catch(error => { });
    });
  }

  private getPendingApprovalCount() {
    this.storage.get(config.ACCESS_TOKEN_IDENTIFIER).then(accessToken => {
      axios.get(`${config.API_URL}/notification/approvals/pending`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json;'
        }
      }).then(json => {
        const data = json.data;
        this.pendingApprovalCounts = data.total_approval;
        this.sumNotificationCounter();
      }).catch(error => { });
    });
  }

  private async getHomeId(filter: any): Promise<void> 
  {
    try {
      const { data } = await this.chatRepository.getHomeId({
        filter_by : filter,
      });
      const chat = (new ChatMapper()).mapFrom({
        chat: data,
        user: this.user
      });
      this.chatRooms = chat.chatRooms;
      this.chatHome = chat.chatHome;
      this.unreadMessage = this.chatRooms.map(item => item.meta?.unread_messages ?? 0).reduce((prev, current) => prev + current, 0);
      this.sumNotificationCounter();
    } catch (error) {
      this.displayError();
    }
  }
  private onSocketConnect(): void { }

  private onSocketDisconnect(): void { }

  private async listenLiveChat(data: ChatHomeWebsocketEntity): Promise<void> {
    const roomUuid = await this.storage.get('current_chat_room_opened');
    if (data.event_type === 'new_chat_room') {
      // IF NOT CHECKING THIS , WILL CAUSING ISSUE , USER SELF MESSAGE WILL COUNTED AS UNREAD MESSAGE
      if(!data.chat_room.last_message.is_owner && data.chat_room.uuid != roomUuid) {
        this.unreadMessage++;
        this.sumNotificationCounter();
      }
    }
  }
  
  private connectWebsocket(): void {
    this.echo = new Echo({
      broadcaster: 'socket.io',
      host: config.WEBSOCKET_URL,
      transports: [
        'websocket', 'polling', 'flashsocket',
      ]
    });

    this.echo.connector.socket.on('connect', this.onSocketConnect);
    this.echo.connector.socket.on('disconnect', this.onSocketDisconnect);
    this.echo.channel(`${config.WEBSOCKET_PREFIX}_live_chat`)
    .listen('.' + this.chatHome.websocketID, this.listenLiveChat.bind(this));
  }

  private async setup(): Promise<void> {
    let accessType = await this.storage.get('CFAccessType');
    let filter = '';
    if(accessType == 'online') {
      this.getUnreadNotificationsCount();
      this.getPendingApprovalCount();
      await this.getHomeId(filter);
      this.connectWebsocket();
    }
  }

  ngOnInit() {
    this.setup();
    this.eventService.subscribe('activity-list:notification_read', () => {
      this.unreadNotificationCounts = this.unreadNotificationCounts - 1;
      this.sumNotificationCounter();
    });
    this.filterService.filter$.subscribe(filter => {
      this.getHomeId(filter);
    });
    this.eventService.subscribe('global-long-poll:live_chat', debounce((event) => {
      this.getHomeId('');
      this.eventService.publish('chat-home:refresh');
    }, 1000));
  }

  ngOnDestroy(): void {
    this.eventService.destroy('activity-list:notification_read');
    this.echo.leave(`${config.WEBSOCKET_PREFIX}_live_chat`);
    this.eventService.destroy('global-long-poll:live_chat');
  }
}
