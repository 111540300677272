export interface GlobalEvent {
    event_type: GlobalEventTypeEnum;
    data : any,
    options : any;
}

export enum GlobalEventTypeEnum {
    incoming_call = "incoming_call",
    end_call_from_caller = "end_call_from_caller",
    end_call_from_receiver = "end_call_from_receiver",
    end_call_from_caller_group = "end_call_from_caller_group",
    no_answer = "no_answer",
    testing = "testing",
}