import { Injectable, OnInit } from "@angular/core";
import { Storage } from "@ionic/storage";
import config from "../config";
import axios from "axios";

@Injectable({
  providedIn: "root",
})
export class EntityPermissionService {
  ENTITY_PERMISSION_KEY: string = config.ENTITY_PERMISSION_KEY;
  TOKEN: string = config.ACCESS_TOKEN_IDENTIFIER;

  apiPromise: any = null;
  isLoading = true;
  listPermissions = [];

  constructor(private storage: Storage) {}

  async fetchPermissionByEntity(){
    const accessToken = await this.storage.get(config.ACCESS_TOKEN_IDENTIFIER);

    try {
      const { data } = await axios.get(`${config.API_URL}/permission/get-pwa`, {
        headers: {
          Authorization: 'Bearer ' + accessToken
        },
        timeout: 30000
      });
      this.listPermissions = data.permissions;
    } catch (error) {
      
    }
  }


  async hasPermissionTo(permission: string) : Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if(!this.apiPromise) {
        this.apiPromise = this.fetchPermissionByEntity();
      }
      await this.apiPromise;

      resolve(this.listPermissions.includes(permission));
    })
  }
  
  resetApiPromise() : void{
    this.apiPromise = null
    this.listPermissions = []
  }

}
