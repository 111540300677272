import { Mapper } from "src/core/mapper/interfaces/mapper";
import { UserEntity } from "src/entity/user.entity";
import { UserModel } from "src/model/user.model";

export class UserMapper implements Mapper<UserEntity[], UserModel[]> {
    mapFrom(data: UserEntity[]): UserModel[] {
        const users: UserModel[] = [];

        data.map(user => {
            users.push({
                id: user.id,
                firstName: user.first_name,
                lastName: user.last_name,
                email: user.email,
                phoneNumber: user.phone_number,
                employeeNumber: user.employee_number,
                username: user.username,
                essCompanyID: user.ess_company_id,
                profilePictureURL: user.profile_picture_url,
                signedProfilePictureURL: user.signed_profile_picture_url,
                jobTitle: null,
                createdAt: user.created_at,
                updatedAt: user.updated_at,
                deletedAt: user.deleted_at
            });
        });

        return users;
    }

    mapTo(data: UserModel[]): UserEntity[] {
        return null;
    }
}