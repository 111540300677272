import { Storage } from '@ionic/storage';
import axios, { AxiosResponse } from "axios";
import config from 'src/config';
import { Injectable } from '@angular/core';
import { IChatRepository } from 'src/core/chat/interfaces/chat-repository.interface';
import { ChatEntity } from 'src/entity/chat/chat.entity';
import { ViewChatParam } from 'src/core/chat/interfaces/view-chat-param.interface';
import { ViewChatEntity } from 'src/entity/chat/view-chat.entity';
import { SendMessageParam } from 'src/core/chat/interfaces/send-message-param.interface';
import { SendMessageEntity } from 'src/entity/chat/send-message.entity';
import { UploadFileMessageEntity } from 'src/entity/chat/upload-file-message.entity';
import { SearchPersonParam } from 'src/core/chat/interfaces/search-person-param.interface';
import { SearchPersonEntity } from 'src/entity/chat/search-person.entity';
import { MuteChatType } from 'src/core/chat/types/mute-chat.type';
import { ChatRoomInfoEntity } from 'src/entity/chat/chat-room-info.entity';
import { ChatHomeFilterParam } from 'src/core/chat/types/chat-home-filter-param.type';
import { SearchChatEntity } from 'src/entity/chat/search-chat.entity';
import { SendSticker } from 'src/entity/chat/send-sticker.entity';
import { SpecificChatEntity } from 'src/entity/chat/specific-chat.entity';
import { ChatHREntity } from 'src/entity/chat/chat-hr.entity';
import { ChatUrgentEntity } from 'src/entity/chat/chat-urgent.entity';
import { PersonEntity } from 'src/entity/chat/person.entity';
import { ChatVoteEntity } from 'src/entity/chat/chat-vote.entity';
import { ChatGroupEntity } from 'src/entity/chat/chat-group.entity';
import { ChatRoomCallEntity } from 'src/entity/chat/chat-room-call.entity';
import { UpdateMessageEntity } from 'src/entity/chat/update-message.entity';
import { ChatGroupDetailEntity } from 'src/entity/chat/chat-group-detail.entity';

@Injectable()
export class ChatRepository extends IChatRepository {
    private http = axios.create({
        baseURL: config.API_URL
    });

    constructor(
        private storage: Storage
    ) {
        super();
    }

    private async getToken(): Promise<String> {
        return await this.storage.get(config.ACCESS_TOKEN_IDENTIFIER)
    }

    public async getHomeId(params?: { filter_by: ChatHomeFilterParam[] }): Promise<AxiosResponse<ChatEntity>> {
        const token = await this.getToken();
        return await this.http.get<ChatEntity>('/live-chat/home', {
            params,
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    public async searchChat(params: { keywords: String; }): Promise<AxiosResponse<SearchChatEntity>> {
        const token = await this.getToken();
        return await this.http.get<SearchChatEntity>('/live-chat/search', {
            params,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async searchGroupChat(params: { keywords: String; }): Promise<AxiosResponse<SearchChatEntity>> {
        const token = await this.getToken();
        return await this.http.get<SearchChatEntity>('/v2/live-chat/search-group', {
            params,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async viewChat(params: ViewChatParam): Promise<AxiosResponse<ViewChatEntity>> {
        const token = await this.getToken();
        return await this.http.post<ViewChatEntity>('/live-chat/view-chat', params, {
            params: {
                page: params.page ?? 1
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async storeMessage(params: SendMessageParam): Promise<AxiosResponse<SendMessageEntity>> {
        const token = await this.getToken();
        return await this.http.post<SendMessageEntity>('/live-chat/send', params, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async pinMessage(params: { uuid: String }): Promise<AxiosResponse<any>> {
        const token = await this.getToken();
        return await this.http.post('/live-chat/pin-message', params, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async unpinMessage(params: { uuid: String }): Promise<AxiosResponse<any>> {
        const token = await this.getToken();
        return await this.http.post('/live-chat/unpin-message', params, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async uploadFile(data: FormData): Promise<AxiosResponse<UploadFileMessageEntity>> {
        const token = await this.getToken();
        return await this.http.post('/live-chat/upload-file', data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async deleteMessage(params: { uuid: String }): Promise<AxiosResponse<any>> {
        const token = await this.getToken();
        return await this.http.post('/live-chat/delete-message', params, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async startIndividualChat(params: { user_id: number }): Promise<AxiosResponse<any>> {
        const token = await this.getToken();
        return await this.http.post('/live-chat/start-individual-chat', params, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async startGroupChat(params: { name: String, participants: number[] }): Promise<AxiosResponse<any>> {
        const token = await this.getToken();
        return await this.http.post('/live-chat/start-group-chat', params, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async searchPerson(params: SearchPersonParam): Promise<AxiosResponse<SearchPersonEntity>> {
        const token = await this.getToken();
        return await this.http.post('/live-chat/search-employees', params, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async muteChat(params: { uuid: String, type: MuteChatType }): Promise<AxiosResponse<any>> {
        const token = await this.getToken();
        return await this.http.post('/live-chat/mute-chat', params, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async getChatRoomInfo(params: { uuid: String }): Promise<AxiosResponse<ChatRoomInfoEntity>> {
        const token = await this.getToken();
        return await this.http.get('/live-chat/chat-room/' + params.uuid, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async deleteChat(params: { uuid: String }): Promise<AxiosResponse<any>> {
        const token = await this.getToken();
        return await this.http.post('/live-chat/delete-chat', params, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async leaveGroup(params: { uuid: String}): Promise<AxiosResponse<any>> {
        const token = await this.getToken();
        return await this.http.post('/live-chat/leave-group', params, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async removeMember(params: { uuid: String, user_id: String }): Promise<AxiosResponse<{ error: boolean, message: string }>> {
        const token = await this.getToken();
        return await this.http.post('/v2/live-chat/detail/remove-member', params, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async addGroupAddmin(params: { uuid: String, user_id: String }): Promise<AxiosResponse<{ error: boolean, message: string }>> {
        const token = await this.getToken();
        return await this.http.post('/v2/live-chat/detail/add-admin', params, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async getChatRoomFile(params: { page: number, uuid: String }): Promise<AxiosResponse<any>> {
        const token = await this.getToken();
        return await this.http.get('/live-chat/chat-room/' + params.uuid + '/files', {
            params,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async getChatRoomLink(params: { page: number; uuid: String; }): Promise<AxiosResponse<any>> {
        const token = await this.getToken();
        return await this.http.get('/live-chat/chat-room/' + params.uuid + '/links', {
            params,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async sendSticker(data: FormData): Promise<AxiosResponse<SendSticker>> {
        const token = await this.getToken();
        return await this.http.post('/live-chat/stickers/send', data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }
    
    public async sendZeroMeta(params: { chat_room_uuid: String; }): Promise<AxiosResponse<any>> 
    {
        const token = await this.getToken();
        return await this.http.post('/live-chat/reset-meta', params, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async getSpecificChat(params: { message_uuid: String, chat_room_uuid: String, page: number }): Promise<AxiosResponse<SpecificChatEntity>> 
    {
        const token = await this.getToken();
        return await this.http.post<SpecificChatEntity>('/live-chat/view-chat/' + params.message_uuid, {
            chat_room_uuid: params.message_uuid
        },
        {
            params: {
                page: params.page
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async createHRGroupChat(params: { name: String }): Promise<AxiosResponse<ChatHREntity>> 
    {
        const token = await this.getToken();
        return await this.http.post<ChatHREntity>('/live-chat/create-chat-with-hr-group-chat', params,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async createUrgentGroupChat(params: { name: String }): Promise<AxiosResponse<ChatUrgentEntity>> 
    {
        const token = await this.getToken();
        return await this.http.post<ChatUrgentEntity>('/live-chat/create-urgent-group-chat', params,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async viewChatV2(params: ViewChatParam): Promise<AxiosResponse<ViewChatEntity>> 
    {
        const token = await this.getToken();
        return await this.http.post<ViewChatEntity>('/v2/live-chat/view-chat', params, {
            params: {
                page: params.page ?? 1
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async storeMessageV2(params: SendMessageParam): Promise<AxiosResponse<SendMessageEntity>> 
    {
        const token = await this.getToken();
        return await this.http.post<SendMessageEntity>('/v2/live-chat/send', params, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async uploadFileV2(data: FormData): Promise<AxiosResponse<UploadFileMessageEntity>> 
    {
        const token = await this.getToken();
        return await this.http.post('/v2/live-chat/upload-file', data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async batchUploadFile(data: FormData): Promise<AxiosResponse<any>> 
    {
        const token = await this.getToken();
        return await this.http.post('/v2/live-chat/batch-upload-file', data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }
    
    public override async readMessage(params: { chat_room_uuid: String; }): Promise<AxiosResponse<{ error: boolean; message: String; }>> 
    {
        const token = await this.getToken();
        return await this.http.post('/v2/live-chat/read-message', params, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    /**
     * Pin Message List
     * 
     * @param {number} chatRoomId 
     * @returns {Promise<AxiosResponse<{ error: boolean, message: String }>>}
     */
    public async pinMessageList(chatRoomId: number): Promise<AxiosResponse<{ error: boolean, message: String }>>
    {
        const token = await this.getToken();
        return await this.http.post('/v2/live-chat/pin-message-list', { chatRoomId }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }
  
    /**
     * Search Mass Employee Using Imported CSV
     * 
     * @param {FormData} data
     * @returns {Promise<{ error: boolean, message: string }> }
     */
    public async searchMassEmployees(data: FormData): Promise<AxiosResponse<{ error: boolean, message: string, data: { users: PersonEntity[] }}>> 
    {
        const token = await this.getToken();
        
        return await this.http.post('/v2/live-chat/search-mass-employees', data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }
    public async searchEmployees(params: { 
        employee_name: String;
        companies: String[];
        outlets: String[];
        ranks: String[];
        chatRoomUUID: String;
    }): Promise<AxiosResponse<any>> {
        try {
            const token = await this.getToken();
            const response = await this.http.post('/v2/live-chat/search-employee/', params, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    
            return response.data; 
        } catch (error) {
            console.error('Error searching employees:', error);
            throw error;
        }
    }
    
    public async getCompany(): Promise<any> {
        try {
          const token = await this.getToken();
          const response: AxiosResponse<any> = await this.http.get(`${config.API_URL}/v2/live-chat/company-list`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          return response.data;
        } catch (error) {
          console.error('Error fetching company list:', error);
          throw error;
        }
      }
    
      public async getOutlet(): Promise<any> {
        try {
          const token = await this.getToken();
          const response: AxiosResponse<any> = await this.http.get(`${config.API_URL}/v2/live-chat/outlet-list`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          return response.data;
        } catch (error) {
          console.error('Error fetching outlet list:', error);
          throw error;
        }
      }
    
      public async getRank(): Promise<any> {
        try {
          const token = await this.getToken();
          const response: AxiosResponse<any> = await this.http.get(`${config.API_URL}/v2/live-chat/rank-list`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          return response.data;
        } catch (error) {
          console.error('Error fetching rank list:', error);
          throw error;
        }
      }

    /**
     * Create Vote Feature API
     * 
     * @param {CreateVoteFeatureParam} params 
     * @returns {Promise<AxiosResponse<SendMessageEntity>>}
     */
    public async createVoteFeature(params: FormData): Promise<AxiosResponse<SendMessageEntity>> {
        const token = await this.getToken();
        return await this.http.post<SendMessageEntity>('/live-chat/send', params, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }
  
    /**
     * Setup The Vote Feature
     * 
     * @param {FormData} params 
     * @returns {Promise<AxiosResponse<ChatVoteEntity>>}
     */
    public async setupVoteFeature(params: FormData): Promise<AxiosResponse<ChatVoteEntity>> {
        const token = await this.getToken();
        return await this.http.post<ChatVoteEntity>('/v2/live-chat/setup-vote-feature', params , {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    /**
     * Vote The Chat That Classified As Vote List
     * 
     * @param {FormData} params 
     * @returns {Promise<AxiosResponse<{ error: boolean, message: string }>>}
     */
    public async vote(params: FormData): Promise<AxiosResponse<{ error: boolean, message: string }>> {
        const token = await this.getToken();
        return await this.http.post<{ error: boolean, message: string }>('/v2/live-chat/vote', params, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    /**
     * Setup Chat Group
     * 
     * @param {string} uuid 
     * @returns {Promise<AxiosResponse<ChatGroupEntity>>}
     */
    public async setupChatGroup(uuid: string): Promise<AxiosResponse<ChatGroupEntity>> {
        const token = await this.getToken();
        return await this.http.get(`/v2/live-chat/setup-chat-group/${uuid}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
    }

    /**
     * Remove Selected Participants
     * 
     * @param {FormData} fd 
     * @returns {Promise<AxiosResponse<{ error: boolean, message: string }>>}
     */
    public async removeParticipants(fd: FormData): Promise<AxiosResponse<{ error: boolean, message: string }>> {
        const token = await this.getToken();
        return await this.http.post(`/v2/live-chat/remove-participants`, fd, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    /**
     * Add Selected Participants
     * 
     * @param {FormData} fd 
     * @returns {Promise<AxiosResponse<{ error: boolean, message: string }>>}
     */
    public async addParticipants(fd: FormData): Promise<AxiosResponse<{ error: boolean, message: string }>> {
        const token = await this.getToken();
        return await this.http.post(`/v2/live-chat/add-participants`, fd, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }
    
    public async createChatRoomCall(data: { chat_room_id: number }): Promise<AxiosResponse<{ error: boolean, message: string, chat_room_call: ChatRoomCallEntity }>>
    {
        const token = await this.getToken();
        return await this.http.post(`/live-chat/chat-room-calls`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }
    
    public async findChatRoomCall(id: number): 
    Promise<AxiosResponse<
        { 
            error: boolean, 
            message: string, 
            chat_room_call: ChatRoomCallEntity, 
            otherParticipant: ChatRoomCallEntity, 
            call_data:any,
            group_caller: any,
            group_name: string,
            is_group: boolean 
        }>>
    {
        const token = await this.getToken();
        return await this.http.get(`/live-chat/chat-room-calls/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    // public async endRoomCallFromCaller(data: { chat_room_uuid: any }): Promise<AxiosResponse<{ error: boolean, message: string, chat_room_call: ChatRoomCallEntity }>>
    // {
    //     const token = await this.getToken();
    //     return await this.http.post(`/live-chat/chat-room-calls/end-call-from-caller`, data, {
    //         headers: {
    //             Authorization: `Bearer ${token}`
    //         }
    //     });
    // }

    // public async endRoomCallFromReceiver(data: { chat_room_uuid: any }): Promise<AxiosResponse<{ error: boolean, message: string, chat_room_call: ChatRoomCallEntity }>>
    // {
    //     const token = await this.getToken();
    //     return await this.http.post(`/live-chat/chat-room-calls/end-call-from-receiver`, data, {
    //         headers: {
    //             Authorization: `Bearer ${token}`
    //         }
    //     });
    // }

    public async endRoomCall(data: { chat_room_uuid: any }): Promise<AxiosResponse<{ error: boolean, message: string, chat_room_call: ChatRoomCallEntity }>>
    {
        const token = await this.getToken();
        return await this.http.post(`/live-chat/chat-room-calls/end-call`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    public async seeMembers(uuid: string): Promise<AxiosResponse<ChatGroupEntity>> {
        const token = await this.getToken();
        return await this.http.get(`/v2/live-chat/see-members/${uuid}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
    }

    public async seeVoters(id: string): Promise<AxiosResponse<ChatVoteEntity>> {
        const token = await this.getToken();
        return await this.http.get(`/v2/live-chat/see-voters/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
    }

    public async updateMessage(params: { uuid: String; message: string; }): Promise<AxiosResponse<UpdateMessageEntity>> {
        const token = await this.getToken();
        return await this.http.post(`/v2/live-chat/update-message`, params, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }
    
    public async setupChatGroupDetail(uuid: string): Promise<AxiosResponse<ChatGroupDetailEntity>> {
        const token = await this.getToken();
        return await this.http.get(`/v2/live-chat/detail/${uuid}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
    }

    public async storeChatGroupDetail(fd: FormData): Promise<AxiosResponse<{ message: string }>> {
        const token = await this.getToken();
        return await this.http.post(`/v2/live-chat/detail`, fd, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async updateChatGroupDetail(fd: FormData, id: string): Promise<AxiosResponse<{ message: string }>> {
        const token = await this.getToken();
        fd.append('_method', 'PATCH');
        return await this.http.post(`/v2/live-chat/detail/${id}`, fd, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    public async cloneMessage(params: { chat_room_uuid: string; parent_uuid: String; }): Promise<AxiosResponse<{ message: string }>> 
    {
        const token = await this.getToken();
        return await this.http.post<{ message: string }>('/v2/live-chat/clone', params, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }
}